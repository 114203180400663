<template>
  <div class="businesses p-8">
    <div class="mx-auto max-w-5xl space-y-6">
      <div class="flex justify-start items-center gap-1">
        <svg
          @click="router.go(-1)"
          class="h-8 w-8 text-blue-600 hover:text-blue-700"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z"
          />
        </svg>
        <h1 class="text-3xl font-semibold">Edit Business</h1>
      </div>

      <div v-if="isLoading" class="flex justify-center gap-1">
        <div class="spinner dark"></div>
        <div>Loading</div>
      </div>

      <form @submit.prevent="submitUpdate" class="max-w-3xl text-lg space-y-5">
        <div>
          <h3 class="text-xl font-semibold mt-10">
            Business Info
          </h3>
        </div>

        <div>
          <label
            for="business_logo"
            class="block text-sm font-medium text-gray-600"
          >
            Logo (250px x 200px)
          </label>
          <div class="mt-1">
            <input
              @change="selectBusinessLogo"
              type="file"
              id="logo_picker"
              name="business_logo"
              class="w-full border rounded px-4 py-2"
            />
          </div>
          <div
            class="relative w-72 object-cover"
            v-if="business.business_logo || (imageData && imageData.preview)"
          >
            <svg
              @click="removeImage()"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              class="w-7 h-7 text-black bg-white rounded-full cursor-pointer absolute -top-2 -right-2"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                clip-rule="evenodd"
              />
            </svg>

            <img
              class="mt-2 w-full h-full object-cover"
              :src="
                imageData && imageData.preview
                  ? imageData.preview
                  : businessLogo
              "
            />
          </div>
        </div>

        <div>
          <label
            for="business_name"
            class="block text-sm font-medium text-gray-600"
          >
            Business Name <span class="text-red-600">*</span>
          </label>
          <div class="mt-1">
            <input
              v-model="business.business_name"
              required
              name="business_name"
              class="w-full border rounded px-4 py-2"
            />
          </div>
        </div>

        <div>
          <label
            for="business_phone"
            class="block text-sm font-medium text-gray-600"
          >
            Phone (xxx) xxx-xxxx
          </label>
          <div class="mt-1">
            <input
              @change="
                business.business_phone = formatPhone(business.business_phone)
              "
              v-model="business.business_phone"
              type="tel"
              pattern="[+1(\s]*[2-9]{1}[0-9]{2}[-.)\s]*[0-9]{3}[-.\s]*[0-9]{4}"
              name="business_phone"
              class="w-full border rounded px-4 py-2 border-gray-200"
            />
          </div>
        </div>

        <div>
          <label
            for="business_address1"
            class="block text-sm font-medium text-gray-600"
          >
            Address
          </label>
          <div class="mt-1">
            <input
              v-model="business.business_address1"
              maxlength="45"
              name="business_address1"
              class="w-full border rounded px-4 py-2"
            />
          </div>
          <div class="mt-2">
            <input
              v-model="business.business_address2"
              maxlength="45"
              name="business_address2"
              class="w-full border rounded px-4 py-2"
            />
          </div>
        </div>

        <div class="flex gap-2">
          <div class="w-1/3">
            <label
              for="business_city"
              class="block text-sm font-medium text-gray-600"
            >
              City
            </label>
            <div class="mt-1">
              <input
                v-model="business.business_city"
                name="business_city"
                class="w-full border rounded px-4 py-2"
              />
            </div>
          </div>
          <div class="w-1/3">
            <label
              for="business_state"
              class="block text-sm font-medium text-gray-600"
            >
              State
            </label>
            <div class="mt-1">
              <select
                v-model="business.business_state"
                name="business_state"
                class="w-full border rounded px-4 py-2 border-gray-200 text-lg"
              >
                <option value=""> </option>
                <option value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="DC">District Of Columbia</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
              </select>
            </div>
          </div>
          <div class="w-1/3">
            <label
              for="business_zip"
              class="block text-sm font-medium text-gray-600"
            >
              Zip
            </label>
            <div class="mt-1">
              <input
                v-model="business.business_zip"
                name="business_zip"
                class="w-full border rounded px-4 py-2"
              />
            </div>
          </div>
        </div>

        <div v-show="user.claims?.user_role == 'superadmin'">
          <label
            for="business_type"
            class="block text-sm font-medium text-gray-600"
          >
            Billing Type <span class="text-red-600">*</span>
          </label>
          <div class="mt-1">
            <select
              v-model="business.business_type"
              required
              name="business_type"
              class="w-full border rounded px-4 py-2 border-gray-200 text-lg"
            >
              <option value="invoiced">Invoiced</option>
              <option value="stripe">Stripe</option>
            </select>
          </div>
        </div>

        <div v-show="user.claims?.user_role == 'superadmin'">
          <label
            for="business_status"
            class="block text-sm font-medium text-gray-600"
          >
            Status <span class="text-red-600">*</span>
          </label>
          <div class="mt-1">
            <select
              v-model="business.business_status"
              required
              name="business_status"
              class="w-full border rounded px-4 py-2 border-gray-200 text-lg"
            >
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </select>
          </div>
        </div>

        <div>
          <label
            for="email_from"
            class="block text-sm font-medium text-gray-600"
          >
            Default From Email
          </label>
          <div class="mt-1">
            <input
              v-model="business.settings_default_email_from"
              type="email"
              name="email_from"
              class="w-full border rounded px-4 py-2 border-gray-200 text-lg"
            />
          </div>
        </div>

        <div>
          <h3 class="text-xl font-semibold mt-10">
            Quote settings
          </h3>
        </div>

        <div>
          <label
            for="email_content"
            class="block text-sm font-medium text-gray-600"
          >
            Quote Email Template
          </label>
          <div class="mt-1">
            <textarea
              v-model="business.settings_default_email_content"
              name="email_content"
              class="w-full border rounded px-4 py-2 border-gray-200 text-lg"
              rows="8"
            />
          </div>
        </div>

        <div>
          <label
            for="header_extras"
            class="text-sm font-medium text-gray-600 flex justify-between"
          >
            <span>Quote Header Extras (2 lines max, 46 per line)</span>
            <span>{{ quote_header_extras_length }} / 92</span>
          </label>
          <div class="mt-1">
            <textarea
              @keypress="listenQuoteHeader($event)"
              v-model="business.quote_header_extras"
              name="header_extras"
              class="w-full border rounded px-4 py-2 border-gray-200 text-lg"
              rows="3"
            />
          </div>
        </div>

        <div>
          <label
            for="acceptance_days"
            class="block text-sm font-medium text-gray-600"
          >
            Number of Days for Acceptance
          </label>
          <div class="mt-1">
            <input
              v-model="business.quote_acceptance_days"
              name="acceptance_days"
              type="number"
              class="w-full border rounded px-4 py-2 border-gray-200 text-lg"
            />
          </div>
        </div>

        <div>
          <label
            for="quote_footer_1"
            class="block text-sm font-medium text-gray-600"
          >
            Quote Footer 1
          </label>
          <div class="mt-1">
            <textarea
              v-model="business.quote_footer_1"
              name="quote_footer_1"
              class="w-full border rounded px-4 py-2 border-gray-200 text-lg"
              rows="3"
            />
          </div>
        </div>

        <div>
          <label
            for="quote_footer_2"
            class="block text-sm font-medium text-gray-600"
          >
            Quote Footer 2
          </label>
          <div class="mt-1">
            <textarea
              v-model="business.quote_footer_2"
              name="quote_footer_2"
              class="w-full border rounded px-4 py-2 border-gray-200 text-lg"
              rows="3"
            />
          </div>
        </div>

        <div>
          <label
            for="quote_footer_3"
            class="block text-sm font-medium text-gray-600"
          >
            Quote Footer 3
          </label>
          <div class="mt-1">
            <textarea
              v-model="business.quote_footer_3"
              name="quote_footer_3"
              class="w-full border rounded px-4 py-2 border-gray-200 text-lg"
              rows="3"
            />
          </div>
        </div>

        <div>
          <h3 class="text-xl font-semibold mt-10">
            Job settings
          </h3>
        </div>

        <div>
          <label
            for="job_email_template"
            class="block text-sm font-medium text-gray-600"
          >
            Job Completed Email Template
          </label>
          <div class="mt-1">
            <textarea
              v-model="business.job_email_template"
              name="job_email_template"
              class="w-full border rounded px-4 py-2 border-gray-200 text-lg"
              rows="3"
            />
          </div>
        </div>

        <div>
          <h3 class="text-xl font-semibold mt-10">
            Invoice settings
          </h3>
        </div>

        <div>
          <label
            for="invoice_footer"
            class="block text-sm font-medium text-gray-600"
          >
            Invoice Footer
          </label>
          <div class="mt-1">
            <textarea
              v-model="business.invoice_footer"
              name="invoice_footer"
              class="w-full border rounded px-4 py-2 border-gray-200 text-lg"
              rows="3"
            />
          </div>
        </div>

        <div>
          <h3 class="text-xl font-semibold mt-10">
            Mailgun settings
          </h3>
        </div>

        <div>
          <label
            for="mailgun_api_key"
            class="block text-sm font-medium text-gray-600"
          >
            Mailgun API Key
          </label>
          <div class="relative">
            <input
              id="password"
              v-model="business.mailgun_api_key"
              :type="showAPI ? 'text' : 'password'"
              class="appearance-none rounded-none relative block w-full pl-3 pr-10   py-2 border border-gray-200 placeholder-gray-500 text-gray-900 rounded-b focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10"
            />

            <svg
              xmlns="http://www.w3.org/2000/svg"
              @click="showAPI = !showAPI"
              v-if="!showAPI"
              class="h-6 w-6 absolute top-6 right-2 cursor-pointer z-10 -mt-4 text-gray-500"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 absolute top-6 right-2 cursor-pointer z-10 -mt-4 text-gray-500"
              fill="none"
              v-if="showAPI"
              @click="showAPI = !showAPI"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
              />
            </svg>
          </div>
        </div>

        <div>
          <label
            for="mailgun_domain"
            class="block text-sm font-medium text-gray-600"
          >
            Mailgun Domain
          </label>
          <div class="mt-1">
            <input
              v-model="business.mailgun_domain"
              name="mailgun_domain"
              type="text"
              class="w-full border rounded px-4 py-2 border-gray-200 text-lg"
            />
          </div>
        </div>

        <div class="pt-2 flex justify-center">
          <button
            type="submit"
            class="bg-blue-600 submit-btn text-white text-2xl font-bold w-60 rounded py-2 flex justify-center gap-2"
          >
            <div v-if="isPending" class="spinner"></div>
            Save
          </button>
        </div>

        <div class="text-center text-red-600 font-semibold">
          {{ error }} {{ write_err }} {{ phone_err }}
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { ref, watchEffect } from "vue";
import { useRoute, useRouter } from "vue-router";
import getDocument from "@/composables/getDocument";
import useDocument from "@/composables/useDocument";
import usePhone from "@/composables/usePhone";
import useStorage from "@/composables/useStorage";

export default {
  props: ["id", "user"],
  setup(props) {
    const isLoading = ref(true);
    const isPending = ref(false);
    const showAPI = ref(false);
    const businessLogo = ref("");
    const route = useRoute();
    const quote_header_extras_length = ref(0);
    const imageData = ref({
      value: "",
      preview: "",
      name: "",
    });
    const router = useRouter();
    const business_id = props.user.claims.business_id ?? props.id;

    const { error, document: business } = getDocument(
      `businesses`,
      business_id
    );

    const { getDownloadURL: getDownloadURL } = useStorage(
      business_id,
      "assets"
    );
    let isImageFirstTime = false;

    watchEffect(async () => {
      if (business.value) {
        isLoading.value = false;
      }
      if (!isImageFirstTime && business.value && business.value.business_logo) {
        businessLogo.value = await getDownloadURL(business.value.business_logo);
        isImageFirstTime = !isImageFirstTime;
      }
      // prevText = business.value.quote_header_extras;
      quote_header_extras_length.value =
        business.value.quote_header_extras?.length;

      // console.log("lent ", business.value.quote_header_extras);
    });

    const { error: write_err, updateDoc } = useDocument(
      `businesses`,
      business_id
    );

    const selectBusinessLogo = (event) => {
      const file = event.target.files[0];
      // console.log("file is ", file);
      imageData.value.name = new Date().getTime() + "_" + file.name;
      imageData.value.value = file;
      imageData.value.preview = URL.createObjectURL(file);
    };

    const removeImage = () => {
      if (!imageData.value.value) {
        business.value.business_logo = "";
      } else {
        imageData.value.value = null;
        imageData.value.name = null;
        imageData.value.preview = null;

        const picker = document.getElementById("logo_picker");
        picker.value = "";
      }
    };

    const { error: phone_err, formatPhone } = usePhone();
    let temp = ["", ""];
    // let prevHeader = "";
    const listenQuoteHeader = async () => {
      const textValue = business.value.quote_header_extras;
      // console.log("text val is ", ev, textValue);

      // const inputType = ev.inputType;
      let headerLines = String(textValue).split("\n");
      // if (inputType == "insertLineBreak") {
      //   console.log("header lines lent", headerLines);
      //   console.log("header value ", business.value.quote_header_extras);
      //   if (headerLines.length == 3) {
      //     console.log("hheader lines lent ", headerLines);
      //     headerLines.pop();
      //   }
      // } else {
      if (headerLines.length != 3) {
        quote_header_extras_length.value = textValue.length;
        headerLines.forEach((line, index) => {
          if (line.length > 46) {
            headerLines[index] = temp[index];
            // console.log("headerline ", index, line);
          } else {
            temp[index] = line;
            // console.log("continue typing", temp);
          }
        });

        const line2 = headerLines[1] ?? "";
        // console.log(" hhheader lines are ", headerLines, "temps are ", temp);
        business.value.quote_header_extras = headerLines[0] + "\n" + line2;
        quote_header_extras_length.value =
          business.value.quote_header_extras.length;
        // prevHeader = business.value.quote_header_extras;
        // console.log("prev headers ", prevHeader);
        // console.log("real text data ", business.value.quote_header_extras);
      } else {
        // console.log("temp is ", temp);
        if (temp[0] == "" || temp[1] == "") {
          temp = [...headerLines];
        }
        if (business.value.quote_header_extras == "") {
          // console.log("lent is zero");
        }
        business.value.quote_header_extras = temp[0] + "\n" + temp[1];
      }
    };

    const submitUpdate = async () => {
      isPending.value = true;
      const { uploadFile: uploader } = useStorage(business_id, "assets");

      await uploader(imageData.value);
      // console.log("business logo is ", business.value.business_logo);
      const image =
        business.value.business_logo != "" &&
        imageData.value &&
        imageData.value.value == ""
          ? business.value.business_logo
          : imageData.value && imageData.value.value == ""
          ? ""
          : `${business_id}/assets/${imageData.value.name}`;

      await updateDoc({
        business_name: business.value.business_name,
        business_logo: image ?? "",
        business_phone: business.value.business_phone ?? "",
        business_type: business.value.business_type,
        business_status: business.value.business_status,
        business_address1: business.value.business_address1 ?? "",
        business_address2: business.value.business_address2 ?? "",
        business_city: business.value.business_city ?? "",
        business_state: business.value.business_state ?? "",
        business_zip: business.value.business_zip ?? "",
        quote_header_extras: String(business.value.quote_header_extras) ?? "",
        // quote_header_extras2: header_extras2 ?? "",
        quote_acceptance_days: business.value.quote_acceptance_days ?? "",
        quote_footer_1: business.value.quote_footer_1 ?? "",
        quote_footer_2: business.value.quote_footer_2 ?? "",
        quote_footer_3: business.value.quote_footer_3 ?? "",
        invoice_footer: business.value.invoice_footer ?? "",
        settings_default_email_from: business.value.settings_default_email_from,
        settings_default_email_content:
          business.value.settings_default_email_content,
        job_email_template: business.value.job_email_template ?? "",
        mailgun_domain: business.value.mailgun_domain ?? "",
        mailgun_api_key: business.value.mailgun_api_key ?? "",
      });
      isPending.value = false;
      if (!write_err.value) {
        router.push(route.path.substring(0, route.path.lastIndexOf("/")));
      }
    };

    return {
      isLoading,
      router,
      error,
      business,
      write_err,
      showAPI,
      phone_err,
      formatPhone,
      businessLogo,
      submitUpdate,
      listenQuoteHeader,
      imageData,
      removeImage,
      quote_header_extras_length,
      isPending,
      selectBusinessLogo,
    };
  },
};
</script>
